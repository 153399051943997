.activitiesHolder {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  max-width: 100%;
}

.activities {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

@media screen and (max-width: 1100px) {
  .activitiesHolder {
    padding: 0 1rem;
  }
  .activities {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media screen and (max-width: 720px) {
  
  .activities {
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .activities {
    grid-template-columns: repeat(1, 1fr);
  }
}
