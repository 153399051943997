.card {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 5px 0 5px;
  width: 100%;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.imageContainer {
  position: relative;
  overflow: hidden;
  width: 18vw;
  max-width: 270px;
  height: 18vw;
  max-height: 270px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
}

.staffImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContent {
  padding: 1rem;
  max-height: 10rem;
}

.cardContent h3 {
  z-index: 2;
  font-size: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.cardContent p {
  z-index: 2;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

@media screen and (max-width: 1100px) {
  .imageContainer {
    width: 24vw;
    height: 24vw;
  }
}


@media screen and (max-width: 720px) {
  
  .imageContainer {
    width: 36vw;
    height: 36vw;
  }
}

@media screen and (max-width: 480px) {
  .imageContainer {
    width: 70vw;
    height: 70vw;
  }
}
