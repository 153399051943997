.map-container {
  height: 400px;
  border-radius: 4px;
  overflow: hidden;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

form {
  width: 100%;
  padding: 0 40px !important;
}

label {
  color: white !important;
  font-weight: 600 !important;
}

.pages-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pages-list > * {
  margin-bottom: 4px;
}

.info-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-list > * {
  margin-bottom: 4px;
}

.info-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-list span {
  margin-right: 10px;
}