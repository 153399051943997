.actCard {
  position: relative;
  display: block;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 5px 0 5px;
  width: 100%;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}


.actImageContainer {
  position: relative;
  overflow: hidden;
  height: 11vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activityImage {
  width: 100%;
}

.actCardContent {
  padding: 1rem;
}

.actCardContent h3 {
  z-index: 2;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.actCardContent p {
  z-index: 2;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
}

.activityContactInfo {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.redOnHover:hover {
  color: red;
}

@media screen and (max-width: 1100px) {
  .actImageContainer {
    height: 15vw;
  }
}

@media screen and (max-width: 960px) {
  .actImageContainer {
    height: 14vw;
  }
}


@media screen and (max-width: 720px) {
  
  .actImageContainer {
    height: 24vw;
  }
}

@media screen and (max-width: 480px) {
  .actImageContainer {
    height: 50vw;
  }

  .actCardContent p {
    display: none;
  }
}
