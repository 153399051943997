.sponsorsHolder {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  max-width: 100%;
}

.sponsorsHolder section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}

.sponsorsFull img {
  width: 260px;
}

.sponsorsStandard img {
  width: 220px;
}

.sponsorsBasic img {
  width: 180px;
}

.sponsorItem {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sponsorItem img {
  opacity: 0.7;
}

.sponsorItem img:hover {
  opacity: 1;
}

@media screen and (max-width: 1100px) {
  .eventsHolder {
    padding: 0 1rem;
  }
  .events {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media screen and (max-width: 720px) {
  
  .events {
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .events {
    grid-template-columns: repeat(1, 1fr);
  }
}
